const URL_CONSTANT = {
  API_BASE: 'https://api.appinsights.ai/',
};

const DEFAULT_FILTER = '- all -';

const DATE_FORMAT = {
  PRIMARY: 'DD-MM-YYYY',
  SECONDARY: 'MM-DD-YYYY',
  MONTH_DATE: 'MM-YYYY',
  DATE_PICKER: 'DD/MM/YYYY',
  MONTH_DATE_GRAPH: "MMM 'YY",
  WEEK_DATE_GRAPH: "DD MMM 'YY",
  DAY_DATE_GRAPH: "DD MMM 'YY",
  DAY_DATE_GRAPH2: "DDMMM'YY",

};

const BAR_GRAPH = {
  NestedFeature: 'rgb(64, 230, 173)',
  CustomObject: '#3366FF',
  LightningPage: 'rgb(167, 199, 231)',
  LightningComponent: 'rgb(25, 25, 112)',
  VisualforcePage: 'rgb(93, 63, 211)',
  ApexClass: 'rgb(100, 199, 237)',
  ApexTrigger: 'rgb(80, 150, 142)',
  PartialData: 'rgb(255,165,0)',
  CompleteData: '#4482FF',
};

const TITLES = {
  HEALTH_SCORE_CONFIGURATION: 'KPI Score Configuration',
  ACTIVE_USER_CONFIGURATION: 'Active User Configuration',
  USER_MANAGER: 'User Manager',
  TENANT_MANAGER: 'Tenant Manager',
  PACKAGE_MANAGER: 'Package Manager',
  PROFILE: 'My Profile',
  SALESFORCE_CONFIG: 'SalesForce Configuration',
  LOG_ANALYZER: 'Log Analyzer',
};

const COLUMNS = {
  Label: 'Label',
  LastModified: 'Last Modified',
  Actions: 'Actions',
};

const BUTTONS = {
  NEW_HEALTH_SCORE: 'New KPI Score Configuration',
  IMPERSONATE_TENANT: 'Impersonate',
  CREATE_NEW_USER: 'Create User',
  CREATE_TENANT: 'Create Tenant',
  LOGOUT: 'Logout',
  NEW_KPI: 'Define New KPI',
};

const LICENSE_ASSIGNMENT = {
  SITE_WIDE_LICENSE: 'Site Wide License',
  LESS_25: 'Less than 25%',
  FROM_25_50: '25% - 50%',
  FROM_50_75: '50% - 75%',
  FROM_75_100: '75% - 100%',
};

const MUTEDTEXT = {
  HEALTH_SCORE_CONFIGURATION_LABEL_SUB_TEXT: '90 accounts have this score assigned(view)',
};

export {
  URL_CONSTANT, DATE_FORMAT, BAR_GRAPH, TITLES, COLUMNS, LICENSE_ASSIGNMENT, BUTTONS, MUTEDTEXT, DEFAULT_FILTER,
};
