import { createSlice, current } from '@reduxjs/toolkit';
import {
  addHealthScore,
  deleteHealthScore,
  explainHealthScore,
  getHealthInsightsDetails,
  getHealthInsightsList,
  getHealthScoreRuleGraphData,
  updateHealthScore,
} from './HealthScoreAction';

const healthInsightsSlice = createSlice({
  name: 'healthInsightsList',
  initialState: { healthInsightsList: [], error: null, loading: false },
  reducers: {},
  extraReducers: (builder) => {
    // list
    builder.addCase(getHealthInsightsList.pending, (state) => {
      state.loading = true;
      state.healthInsightsList = [];
    });
    builder.addCase(getHealthInsightsList.fulfilled, (state, { payload }) => {
      state.healthInsightsList = payload.responseArray;
      state.loading = false;
    });
    builder.addCase(getHealthInsightsList.rejected, (state, action) => {
      state.loading = false;
      state.healthInsightsList = [];
      state.error = action.payload;
    });
    // delete
    builder.addCase(deleteHealthScore.pending, (state) => {
      state.loading = true;
      state.error = null;
    });
    builder.addCase(deleteHealthScore.fulfilled, (state, { payload }) => {
      const currentState = current(state);
      const updateData = currentState.healthInsightsList && currentState.healthInsightsList.length
        ? currentState.healthInsightsList.filter((obj) => obj.healthScoreId !== payload)
        : [];
      state.loading = false;
      state.error = null;
      state.healthInsightsList = updateData;
    });
    builder.addCase(deleteHealthScore.rejected, (state, action) => {
      state.loading = false;
      state.error = action.payload;
    });
    // update
    builder.addCase(updateHealthScore.pending, (state) => {
      const currentState = current(state);
      state.loading = true;
      state.error = null;
      state.healthInsightsList = [...currentState.healthInsightsList];
    });
    builder.addCase(updateHealthScore.fulfilled, (state, { payload }) => {
      const currentState = current(state);
      const featureList = [...currentState.healthInsightsList];
      const updateData = featureList.map((obj) => {
        if (obj.healthScoreId === payload.healthScoreId) {
          return { ...obj, ...payload };
        }
        if (payload.primaryHealthScore) {
          return { ...obj, primaryHealthScore: false };
        }
        return obj;
      });
      state.loading = false;
      state.error = null;
      state.healthInsightsList = updateData;
    });
    builder.addCase(updateHealthScore.rejected, (state) => {
      const currentState = current(state);
      state.loading = true;
      state.error = null;
      state.healthInsightsList = [...currentState.healthInsightsList];
    });
    // add
    builder.addCase(addHealthScore.pending, (state) => {
      const currentState = current(state);
      state.loading = true;
      state.error = null;
      state.healthInsightsList = [...currentState.healthInsightsList];
    });
    builder.addCase(addHealthScore.fulfilled, (state, { payload }) => {
      const currentState = current(state);
      const obj = {
        healthScoreId: payload.record?.healthScoreId,
        healthScoreLabel: payload.record?.healthScoreLabel,
        primaryHealthScore: payload.record?.primaryHealthScore,
        lastModified: payload.record?.updatedAt,
      };
      const healthInsightsList = [...currentState.healthInsightsList];
      healthInsightsList[healthInsightsList.length] = obj;
      state.loading = false;
      state.error = null;
      state.healthInsightsList = healthInsightsList;
    });
    builder.addCase(addHealthScore.rejected, (state) => {
      const currentState = current(state);
      const healthInsightsList = [...currentState.healthInsightsList];
      state.loading = false;
      state.error = null;
      state.healthInsightsList = healthInsightsList;
    });
  },
});

const healthInsightsDetailsSlice = createSlice({
  name: 'healthInsightsDetailsSlice',
  initialState: { healthInsightsDetails: {} },
  reducers: {
    resetHealthScoreDetails: (state) => {
      state.healthInsightsDetails = {};
      state.loading = false;
      state.error = null;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(getHealthInsightsDetails.pending, (state) => {
      state.loading = true;
      state.healthInsightsDetails = {};
      state.error = null;
    });
    builder.addCase(getHealthInsightsDetails.fulfilled, (state, { payload }) => {
      state.healthInsightsDetails = payload;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getHealthInsightsDetails.rejected, (state, action) => {
      state.loading = false;
      state.healthInsightsDetails = {};
      state.error = action.payload;
    });
  },
});

const healthScoreGraphSlice = createSlice({
  name: 'healthScoreGraphSlice',
  initialState: { healthScoreGraphData: [] },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getHealthScoreRuleGraphData.pending, (state) => {
      state.loading = true;
      state.healthScoreGraphData = [];
      state.error = null;
    });
    builder.addCase(getHealthScoreRuleGraphData.fulfilled, (state, { payload }) => {
      state.healthScoreGraphData = payload.responseArray;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(getHealthScoreRuleGraphData.rejected, (state, action) => {
      state.loading = false;
      state.healthScoreGraphData = [];
      state.error = action.payload;
    });
  },
});

const healthScoreExplainSlice = createSlice({
  name: 'healthScoreExplainSlice',
  initialState: { healthScoreExplainData: {} },
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(explainHealthScore.pending, (state) => {
      state.loading = true;
      state.healthScoreExplainData = {};
      state.error = null;
    });
    builder.addCase(explainHealthScore.fulfilled, (state, { payload }) => {
      state.healthScoreExplainData = payload.responseArray;
      state.loading = false;
      state.error = null;
    });
    builder.addCase(explainHealthScore.rejected, (state, action) => {
      state.loading = false;
      state.healthScoreExplainData = {};
      state.error = action.payload;
    });
  },
});

export const { resetHealthScoreDetails } = healthInsightsDetailsSlice.actions;

export {
  healthInsightsSlice, healthInsightsDetailsSlice, healthScoreGraphSlice, healthScoreExplainSlice,
};
