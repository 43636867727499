import { Chip } from '@mui/material';
import InfoIcon from '@mui/icons-material/Info';
import styles from './style';
import useStyles from '../../hooks/useStyles';

function InfoChip(props) {
  const {
    type, color, hidden, fontWeight, sxProps, icon,
  } = props;

  const classes = useStyles(styles)();

  const classString = {
    multiline: classes.multilineChip,
    banner: classes.banner,
  }[type] || classes.info;

  return (
    !hidden ? (
      <Chip
        sx={{ fontWeight: fontWeight ?? 'normal', ...sxProps }}
        className={classString}
        color={color || 'info'}
        {...props}
        icon={icon === true && <InfoIcon color="primary" />}
      />
    ) : null
  );
}

export default InfoChip;
