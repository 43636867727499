import { useEffect, useState } from 'react';
import * as Sentry from '@sentry/react';
import AppDrawer from '../AppDrawer/AppDrawer';
import Error404Page from './Error404Page';
import { ENV } from '../../config';

function ErrorBoundary({ children, message = 'Please try again later', title = 'Something went wrong' }) {
  const [hasError, setHasError] = useState(false);
  Sentry.init({
    dsn: `${ENV !== 'PROD' ? 'https://a7f2e79efaa6fbb64sentry.tools.hl1.in/8' : 'https://a7f2e79efaa6fbb648272055caa75b4b@sentry.tools.hl1.in/8'}`,
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 0.1, // This sets the sample rate at 10%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
  useEffect(() => {
    const handleError = (error, errorInfo) => {
      if (
        error
        && error.message
        && error.message.includes('ResizeObserver loop')
      ) {
        console.warn('Ignoring specific error:', error.message);
      } else {
        // Capture the error in Sentry
        console.error(error, errorInfo);
        Sentry.captureException(error, { extra: errorInfo });
        setHasError(true);
      }
    };
    setHasError(false);

    window.addEventListener('error', handleError);

    return () => {
      window.removeEventListener('error', handleError);
    };
  }, [children]);

  if (hasError) {
    return (
      <>
        <AppDrawer permissions={{ read: true, write: true }} />
        <Error404Page
          title={title}
          message={message}
        />
      </>
    );
  }

  return children;
}

export default ErrorBoundary;
