import { useDispatch, useSelector } from 'react-redux';
import { useEffect } from 'react';
import { CustomDialogBox } from '../../../../components/CustomDialogBox';
import useToggle from '../../../../hooks/useToggle';
import { getHealthScoreTrends } from '../../../../features/HealthScoreTrends/healthScoreTrendsAction';
import HealthScoreTrendsGraph from './HealthScoreTrendsGraph';

function HealthScoreTrendsDialog(props) {
  const {
    orgId, isShare, pkgId, tenantId, accountOrgId, title, view, primaryScoreObj, openGraph, setOpenGraph,
  } = props;

  const { error, loading, healthScoreTrendsData } = useSelector((state) => state.healthScoreTrends);
  const [refreshHealthScoreTrendData, setRefreshHealthScoreTrendData] = useToggle();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getHealthScoreTrends({
      licenseId: orgId, view, isShare, pkgId, tenantId, accountOrgId,
    }));
  }, [refreshHealthScoreTrendData, view, isShare, pkgId, tenantId, accountOrgId, primaryScoreObj]);

  return (
    openGraph ? (
      <CustomDialogBox
        title={title}
        open={openGraph}
        handleClose={setOpenGraph}
        actions
        content={(
          <HealthScoreTrendsGraph
            loading={loading}
            error={error}
            data={healthScoreTrendsData}
            refresh={setRefreshHealthScoreTrendData}
            message="No Data"
            viewOption={view}
          />
      )}
      />
    ) : null
  );
}

export default HealthScoreTrendsDialog;
