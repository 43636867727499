import axios from 'axios';
import { broadcastMessage } from '../../utils/broadcastEvents';
import { ENV } from '../../config';
import generateUrl from './proxy.config';
import { DEFAULT_ERROR, REQUEST_TIMEOUT, TIMEOUT_ERROR } from './constants';

const getValidationErrorMessage = (errors) => {
  const { constraints } = errors[0];
  return Object.values(constraints).join(', ');
};

const getUrl = (path) => {
  const idToken = localStorage.getItem('idToken');
  const result = {
    URL: '',
    headers: {
      Authorization: `Bearer ${idToken}`,
      'Cache-Control': 'no-cache',
      Pragma: 'no-cache',
      Expires: '0',
    },
  };
  const config = generateUrl(path, ENV);
  if (config) {
    if (typeof config === 'string') {
      return { ...result, URL: config };
    }
    return config;
  }
  return result;
};

const request = async (url, options) => {
  const {
    method = 'GET',
    query = {},
    body,
    headers = {},
    headersOverride,
  } = options;
  try {
    const { URL: defaultUrl, headers: headersConsts } = getUrl(url);

    let URL = defaultUrl;
    if (Array.isArray(options.params)) {
      const str = options?.params?.join('/');
      URL = `${defaultUrl}/${str}`;
    } else if (options.params) {
      URL = `${defaultUrl}/${options.params}`;
    }

    const isFormData = body;

    const reqHeaders = headersOverride || {
      ...headersConsts,
      ...headers,
    };

    if (method === 'POST' && !isFormData) {
      reqHeaders['Content-Type'] = 'application/json';
    }

    const response = await axios.request({
      url: URL,
      method,
      headers: reqHeaders,
      params: query,
      data: body,
      timeout: REQUEST_TIMEOUT,
    });
    return { ...response, success: true };
  } catch (errorResponse) {
    if (errorResponse.timeout) {
      return {
        success: false,
        data: null,
        error: TIMEOUT_ERROR,
      };
    }
    if (!errorResponse.response) {
      return {
        success: false,
        data: null,
        error: DEFAULT_ERROR,
      };
    }

    // Handle unauthorized user
    const { message, code, response: { status, data } } = errorResponse;

    if (status === 401) {
      localStorage.removeItem('idToken');
      localStorage.removeItem('accessToken');
      broadcastMessage('LogoutUser');
    }

    let errorMessage;

    if (Array.isArray(message)) {
      errorMessage = getValidationErrorMessage(message);
    } else {
      errorMessage = message;
    }

    const { code: customErrorCode, message: customErrorMessage, ...customErrorResponse } = data;
    if (data.status === 'ERROR' || (customErrorCode && customErrorMessage)) {
      return {
        success: false,
        data: customErrorResponse.data,
        error: {
          code: customErrorCode,
          message: customErrorMessage,
          statusCode: status,
        },
      };
    }

    return {
      success: false,
      data: null,
      error: {
        code,
        message: errorMessage,
        statusCode: typeof errorResponse.statusCode === 'string' ? errorResponse.statusCode : undefined,
      },
    };
  }
};

export default request;
