import { Badge, IconButton, Tooltip } from '@mui/material';

function CustomIcon(props) {
  const {
    Icon,
    tooltip,
    onClick,
    iconSize,
    size = 'medium',
    hidden,
    badge,
    padding,
    margin,
    variant,
    sxProps,
  } = props;

  if (hidden) {
    return null;
  }

  const IconComponent = (
    <Icon fontSize={iconSize || size} />
  );

  const BadgeIconComponent = (
    <Badge color="secondary" variant="dot">
      {IconComponent}
    </Badge>
  );

  return (
    <Tooltip title={tooltip}>
      <IconButton
        size={size}
        color="primary"
        onClick={onClick}
        sx={(theme) => ({
          ...sxProps,
          p: padding && padding,
          m: margin && margin,
          // cursor: onClick ? 'pointer' : 'default',
          ...(variant === 'contained' && { backgroundColor: 'primary.background' }),
          ...(variant === 'outlined' && {
            border: `1px solid ${theme.palette.primary.border}`,
            color: theme.palette.primary.text,
            '&:hover': {
              color: theme.palette.primary.main,
              backgroundColor: theme.palette.primary.lighter,
              borderColor: theme.palette.primary.lighter,
            },
          }),
        })}
      >
        {badge ? BadgeIconComponent : IconComponent}
      </IconButton>
    </Tooltip>
  );
}

export default CustomIcon;
