import { Close, Search } from '@mui/icons-material';
import { TextField, InputAdornment } from '@mui/material';

export default function SearchBar({
  label, value, onChange, onClear, sxProps,
}) {
  return (
    <TextField
      type="search"
      label={label}
      variant="outlined"
      fullWidth
      sx={sxProps}
      onChange={onChange}
      autoComplete="off"
      value={value}
      InputProps={{
        endAdornment: value ? (
          <InputAdornment position="end">
            <Close
              onClick={onClear}
              sx={{ color: (theme) => theme.palette.grey[500], cursor: 'pointer' }}
            />
          </InputAdornment>
        ) : (
          <InputAdornment position="end">
            <Search sx={{ color: (theme) => theme.palette.grey[500], cursor: 'pointer' }} />
          </InputAdornment>
        ),
      }}
    />
  );
}
