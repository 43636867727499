/* eslint-disable camelcase */
import React from 'react';
import { getIn } from 'formik';
import {
  Grid,
  Stack,
  Typography,
  Divider,
  RadioGroup,
  FormControl,
  Radio,
  FormControlLabel,
  Tooltip,
  Box,
  TextField,
  Collapse,
  IconButton,
  useMediaQuery,
} from '@mui/material';
import InfoIcon from '@mui/icons-material/InfoOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import RemoveCircleOutlineIcon from '@mui/icons-material/RemoveCircleOutline';
import AssessmentOutlinedIcon from '@mui/icons-material/AssessmentOutlined';
import AssessmentIcon from '@mui/icons-material/Assessment';
import { InfoChip } from '../../../../components/CustomChip';
import palette from '../../../../theme/palette';
import { toolTipText } from '../../../../utils/toolTipText';
import { healthScoreTargetMetricFeature, healthScoreTargetMetricPackage } from '../../constant';
import { SelectBox } from '../../../../components/FormComponents';
import { useRuleComponent } from './useRuleComponent';
import RangeSlider from '../../../../components/Histogram/RangeSlider';

function RuleComponent({
  index,
  ruleData,
  values,
  touched,
  errors,
  handleChange,
  handleBlur,
  handleRemove,
  handleRuleChange,
  setFieldValue,
  featureManagerListData,
  insert,
}) {
  const getFieldValue = (fieldName) => getIn(values, fieldName);
  const getTouched = (fieldName) => getIn(touched, fieldName);
  const getError = (fieldName) => getIn(errors, fieldName);

  const isSmallScreen = useMediaQuery('(max-width:600px)');
  const ruleType = `healthScoreRules[${index}].ruleType`;
  const targetMetric = `healthScoreRules[${index}].targetMetric`;
  const thresholdMin = `healthScoreRules[${index}].threshold[0]`;
  const thresholdMax = `healthScoreRules[${index}].threshold[1]`;
  const threshold = `healthScoreRules[${index}].threshold`;
  const rulePoints = `healthScoreRules[${index}].rulePoints`;
  const ruleOption = `healthScoreRules[${index}].ruleOption`;
  const isOpenGraph = `healthScoreRules[${index}].isOpenGraph`;

  const touchedRuleType = getTouched(ruleType);
  const errorRuleType = getError(ruleType);
  const touchedTargetMetric = getTouched(targetMetric);
  const errorTargetMetric = getError(targetMetric);
  const touchedThreshold = getTouched(`healthScoreRules[${index}].threshold`);
  const errorThreshold = getError(`healthScoreRules[${index}].threshold`);
  const touchedRulePoints = getTouched(rulePoints);
  const errorRulePoints = getError(rulePoints);
  const touchedRuleOption = getTouched(ruleOption);
  const errorRuleOption = getError(ruleOption);
  const {
    str, useStyles, graphData, handleViewGraph, toggle, isRuleComplete, preventInvalidInput, handleDuplicateRule,
  } = useRuleComponent({
    ruleData,
    featureManagerListData,
    values,
    setFieldValue,
    errors,
    index,
    insert,
  });
  const classes = useStyles();
  return (
    <Grid
      key={index}
      style={{
        backgroundColor: 'white',
        padding: 15,
        marginTop: '10px',
        boxShadow: '1px 1px lightgrey',
      }}
    >
      <Grid container spacing={2}>
        <Grid container item xs={10} spacing={2} alignItems={isSmallScreen ? 'flex-start' : 'center'}>
          <Grid item xs={12}>
            <Grid container direction="row">
              <Grid item xs={1} display="flex">
                <Typography
                  variant="subtitle1"
                  style={{
                    backgroundColor: isRuleComplete ? palette.success.light : palette.error.light,
                    borderRadius: '5px',
                    padding: '2px 10px',
                  }}
                >
                  Rule
                  {index + 1}
                </Typography>
              </Grid>
              {isSmallScreen ? null : (
                <Grid item xs={11} display="flex">
                  <InfoChip
                    label={str}
                    fontWeight="bold"
                    hidden={str?.length <= 0}
                  />
                </Grid>
              ) }
            </Grid>
          </Grid>
        </Grid>
        <Grid container item xs={12} sm={2} spacing={1} justifyContent="end">
          <Grid item>
            {getFieldValue(isOpenGraph) && toggle ? (
              <Tooltip title={toolTipText.healthInsights.SHOW_DISTRIBUTION_SUCCESS}>
                <span>
                  <IconButton
                    disabled={!getFieldValue(targetMetric)}
                    variant="outlined"
                    onClick={() => handleViewGraph(index, 0)}
                  >
                    <AssessmentIcon color={!getFieldValue(targetMetric) ? 'disabled' : 'primary'} />
                  </IconButton>
                </span>
              </Tooltip>
            ) : (
              <Tooltip
                title={getFieldValue(targetMetric) ? toolTipText.healthInsights.SHOW_DISTRIBUTION_SUCCESS : toolTipText.healthInsights.SHOW_DISTRIBUTION_FAIL}
              >
                <span>
                  <IconButton
                    disabled={!getFieldValue(targetMetric)}
                    variant="outlined"
                    onClick={() => handleViewGraph(index, 1)}
                  >
                    <AssessmentOutlinedIcon color={!getFieldValue(targetMetric) ? 'disabled' : 'primary'} />
                  </IconButton>
                </span>
              </Tooltip>
            )}
          </Grid>
          <Grid item>
            <Tooltip title={toolTipText.healthInsights.DUPLICATE_RULE}>
              <IconButton variant="outlined" onClick={() => handleDuplicateRule(index)}>
                <ContentCopyIcon color="primary" />
              </IconButton>
            </Tooltip>

          </Grid>
          <Grid item>
            <Tooltip
              title={values?.healthScoreRules?.length === 1 ? toolTipText.healthInsights.REMOVE_RULE_SUCCESS : toolTipText.healthInsights.REMOVE_RULE_FAIL}
            >
              <span>
                <IconButton
                  variant="outlined"
                  disabled={values?.healthScoreRules?.length === 1}
                  onClick={() => handleRemove(index)}
                >
                  <RemoveCircleOutlineIcon color={values?.healthScoreRules?.length === 1 ? 'disabled' : 'primary'} />
                </IconButton>
              </span>
            </Tooltip>

          </Grid>

        </Grid>
      </Grid>
      <Stack direction={isSmallScreen ? 'column' : 'row'} alignItems="center" spacing={2}>
        {isSmallScreen ? (

          <SelectBox
            title="Select rule type"
            name={ruleType}
            data={[
              { id: 1, value: 'Package Engagement', name: 'Package Engagement' },
              { id: 2, value: 'Feature Usage', name: 'KPI Usage' },
            ]}
            value={getFieldValue(ruleType)}
            onChange={({ target: { value } }) => handleRuleChange(index, value, setFieldValue)}
            disabled={false}
          />
        ) : (
          <>
            <Typography>Pick The Rule Type : </Typography>
            <RadioGroup
              row
              aria-labelledby="demo-radio-buttons-group-label"
              name={ruleType}
              helperText={touchedRuleType && errorRuleType ? errorRuleType : ''}
              value={getFieldValue(ruleType)}
              onChange={({ target: { value } }) => handleRuleChange(index, value, setFieldValue)}
              onBlur={handleBlur}
            >
              <FormControlLabel
                value="Package Engagement"
                control={<Radio />}
                label="Package Engagement"
              />
              <FormControlLabel
                value="Feature Usage"
                control={<Radio />}
                label="KPI Usage"
              />
            </RadioGroup>
          </>
        )}
        {touchedRuleType && errorRuleType && <Typography color="red">{errorRuleType}</Typography>}
      </Stack>
      { isSmallScreen ? null : <Divider /> }
      <Stack direction={isSmallScreen ? 'column' : 'row'} alignItems="center" spacing={2} paddingTop={2}>
        {ruleData.ruleType === 'Feature Usage' && (
        <FormControl style={{ width: isSmallScreen ? '100%' : '20%' }}>
          <SelectBox
            title="KPIs"
            data={featureManagerListData?.map(({ feature_id, feature_name }) => ({
              id: feature_id,
              value: feature_id,
              name: feature_name,
            }))}
            name={ruleOption}
            value={getFieldValue(ruleOption)}
            helperText={touchedRuleOption && errorRuleOption ? errorRuleOption : ''}
            error={Boolean(touchedRuleOption && errorRuleOption)}
            onChange={handleChange}
            onBlur={handleBlur}
          />
        </FormControl>
        )}
        {ruleData.ruleType?.length && (ruleData.ruleOption?.length || ruleData.ruleType === 'Package Engagement') ? (
          <FormControl style={{ width: isSmallScreen ? '100%' : '20%' }}>
            <SelectBox
              data={
          ruleData.ruleType === 'Feature Usage'
            ? healthScoreTargetMetricFeature
            : healthScoreTargetMetricPackage
        }
              title="Target Metric"
              name={targetMetric}
              value={getFieldValue(targetMetric)}
              helperText={touchedTargetMetric && errorTargetMetric ? errorTargetMetric : ''}
              label="Age"
              error={Boolean(touchedTargetMetric && errorTargetMetric)}
              onChange={handleChange}
              onBlur={handleBlur}
            />
          </FormControl>
        ) : null}
        {ruleData.targetMetric?.length ? (
          <Box
            display="flex"
            flexDirection={isSmallScreen ? 'column' : 'row'}
            alignItems={isSmallScreen ? 'flex-start' : 'center'}
            justifyContent={isSmallScreen ? 'flex-start' : 'space-evenly'}
            width="auto"
            gap={2}
          >
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
              <Typography>Range for Scoring</Typography>
              <Tooltip title={toolTipText.healthInsights.THRESHOLD_INFO}>
                <InfoIcon color="primary" />
              </Tooltip>
            </Box>
            <Box sx={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
              <FormControl style={{ width: isSmallScreen ? '100%' : 'auto' }}>
                <TextField
                  disableRestoreFocus
                  id="outlined-number"
                  placeholder="Minimum"
                  type="number"
                  name={thresholdMin}
                  onKeyDown={(e) => preventInvalidInput(e)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  value={getFieldValue(thresholdMin)}
                  error={Boolean(touchedThreshold && errorThreshold)}
                  onChangeCapture={handleChange}
                  inputProps={{ min: 0 }}
                />
              </FormControl>
              {isSmallScreen ? <span> - </span> : <span> to </span>}
              <FormControl style={{ width: isSmallScreen ? '100%' : 'auto' }}>
                <TextField
                  disableRestoreFocus
                  id="outlined-number"
                  placeholder="Ideal"
                  type="number"
                  name={thresholdMax}
                  onKeyDown={(e) => preventInvalidInput(e)}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  inputProps={{ min: 0 }}
                  value={getFieldValue(thresholdMax)}
                  error={Boolean(touchedThreshold && errorThreshold) || ruleData.threshold[1] <= ruleData.threshold[0]}
                  onChangeCapture={handleChange}
                />
              </FormControl>
            </Box>
          </Box>

        ) : null}
      </Stack>

      {ruleData.rulePoints || (ruleData.threshold?.length === 2 && !(ruleData.threshold[1] <= ruleData.threshold[0])) ? (
        <Box display="flex" flexDirection={isSmallScreen ? 'column' : 'row'} alignItems="center" gap="10px" marginTop={1}>
          {isSmallScreen ? (
            <SelectBox
              title="Rule weightage"
              value={getFieldValue(rulePoints)}
              name={rulePoints}
              data={[
                { id: 1, value: '1', name: 'Low' },
                { id: 2, value: '2', name: 'Medium' },
                { id: 3, value: '3', name: 'High' },

              ]}
              onChange={handleChange}
              onBlur={handleBlur}
              style={{ minWidth: 120, marginTop: 8 }}
              disabled={false}
            />

          ) : (
            <>
              <Typography>Rule Weightage : </Typography>
              <RadioGroup
                row
                aria-labelledby="demo-radio--group-label"
                name={rulePoints}
                value={getFieldValue(rulePoints)}
                helperText={touchedRulePoints && errorRulePoints ? errorRulePoints : ''}
                error={Boolean(touchedRulePoints && errorRulePoints)}
                onChange={handleChange}
                onBlur={handleBlur}
                style={{ flexWrap: 'nowrap' }}
              >
                <Tooltip title={toolTipText.healthInsights.LOW_WEIGHTAGE}><FormControlLabel value="1" control={<Radio size="small" />} label="Low" /></Tooltip>
                <Tooltip title={toolTipText.healthInsights.MEDIUM_WEIGHTAGE}><FormControlLabel value="2" control={<Radio size="small" />} label="Medium" /></Tooltip>
                <Tooltip title={toolTipText.healthInsights.HIGH_WEIGHTAGE}><FormControlLabel value="3" control={<Radio size="small" />} label="High" /></Tooltip>
              </RadioGroup>
            </>
          )}
        </Box>
      ) : null}
      <Collapse
        in={getFieldValue(isOpenGraph) && getFieldValue(targetMetric)?.length && toggle}
        className={getFieldValue(isOpenGraph) ? classes.histogramContainer : classes.histogramClosed}
        style={{ transition: 'all 0.2s' }}
      >
        <RangeSlider
          threshold={getFieldValue(threshold)}
          graphData={graphData}
          setFieldValue={setFieldValue}
          index={index}
          targetMetric={getFieldValue(targetMetric)}
        />
      </Collapse>
    </Grid>
  );
}

export default React.memo(RuleComponent);
