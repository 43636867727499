import { Tooltip } from '@mui/material';
import { stringify } from '../../../utils/methods';

export const filtersList = [
  {
    id: 1,
    title: 'Package Version',
    name: 'PkgVersionFilter',
    data: 'versionIdFilterData',
    flex: 2.4,
    disable: false,
  },
  {
    id: 2,
    title: 'Licence Assignment',
    name: 'LicenceAssignmentFilter',
    data: [
      { id: '-all-', name: '-all-' },
      { id: stringify([0, 25]), name: '0 - 25%' },
      { id: stringify([25, 50]), name: '25% - 50%' },
      { id: stringify([50, 75]), name: '50% - 75%' },
      { id: stringify([75, 100]), name: '75% - 100%' },
      { id: stringify([101, 1000]), name: '100% +' },
    ],
    flex: 2.4,
    disable: false,
  },
  {
    id: 3,
    title: 'Licence Status',
    name: 'LicenceStatusFilter',
    data: [
      { id: 'Active', name: 'Active' },
      { id: 'Trial', name: 'Trial' },
      { id: 'Churned', name: 'Churned' },
      { id: 'Active or Trial', name: 'Active or Trial' },
    ],
    flex: 2.4,
    disable: false,
  },
  {
    id: 4,
    title: 'KPI Usage',
    name: 'FeatureUsageFilter',
    data: 'featureUsageFilterData',
    flex: 1.8,
    disable: true,
  },
  {
    id: 9,
    title: 'Mode',
    name: 'useCheckFilter',
    data: [
      { id: 'equals', name: 'Use' },
      { id: 'notEquals', name: 'No Use' },
    ],
    flex: 1,
    disable: true,
  },
  {
    id: 5,
    title: 'Usage',
    name: 'UsageFilter',
    data: [{ id: 1, name: '-all-' }],
    flex: 2,
    disable: true,
  },
  {
    id: 6,
    title: 'Owner',
    name: 'OwnerFilter',
    data: [{ id: 1, name: '-all-' }],
    flex: 2.4,
    disable: true,
  },
  {
    id: 7,
    title: 'Organization Edition',
    name: 'OrgEditionFilter',
    data: 'orgEditionFilterData',
    flex: 2.4,
    disable: false,
  },
  {
    id: 8,
    title: 'Salesforce Instance',
    name: 'SalesforceFilter',
    data: 'salesforceFilterData',
    flex: 2.4,
    disable: true,
  },
];

export const advanceFiltersList = [
  {
    id: 1,
    title: 'Country',
    name: 'CountryFilter',
    data: 'countryFilterData',
    flex: 2.4,
    disable: true,
  },
  {
    id: 2,
    title: 'Language',
    name: 'LanguageFilter',
    data: 'languageFilterData',
    flex: 2.4,
    disable: true,
  },
];

export const columns = (field) => {
  const columnInfo = [
    {
      field: 'accountName',
      headerName: 'Name',
      sortable: true,
      filterable: false,
      flex: 1,
      align: 'left',
    },
    {
      field: 'status',
      headerName: 'Status',
      sortable: false,
      filterable: false,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'center-align--header',
    },
    {
      field: 'renewalDate',
      headerName: 'Expiration Date',
      sortable: true,
      filterable: false,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'center-align--header',
    },
    {
      field: 'seatsAssigned',
      headerName: 'Seats',
      sortable: true,
      filterable: false,
      flex: 1,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'center-align--header',
    },
    {
      field: 'healthScoreTrendThumbnail',
      headerName: 'Trends',
      sortable: false,
      filterable: false,
      minWidth: 100,
      flex: 1,
      type: 'number',
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'center-align--header',
    },
    {
      field,
      headerName: 'Signal',
      sortable: true,
      filterable: false,
      flex: 0.4,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'center-align--header',
      renderHeader: () => (
        <Tooltip title="Heartbeat">
          <img
            alt="wt"
            src="/heartSignal.svg"
            width="80%"
          />
        </Tooltip>
      ),
    },
    {
      field: 'healthScore',
      headerName: 'Score',
      sortable: true,
      filterable: false,
      flex: 0.6,
      align: 'center',
      headerAlign: 'center',
      headerClassName: 'center-align--header',
    },
  ];
  return columnInfo;
};

export const marks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 250,
    label: '250',
  },
  {
    value: 500,
    label: '500',
  },
  {
    value: 750,
    label: '750',
  },
  {
    value: 1000,
    label: '1000',
  },
  {
    value: 1250,
    label: '1000+',
  },
];

export const pickerList = [
  {
    id: 1,
    label: 'Activation Date',
    fromDate: 'ActivationDateFromFilter',
    toDate: 'ActivationDateToFilter',
  },
  {
    id: 2,
    label: 'Renewal Date',
    fromDate: 'RenewalDateFromFilter',
    toDate: 'RenewalDateToFilter',
  },
];

export const pickerType = [
  {
    type: 'From',
  },
  {
    type: 'To',
  },
];
