import { useDispatch, useSelector } from 'react-redux';
import { useNavigate, useLocation, useParams } from 'react-router-dom';
import * as Yup from 'yup';
import { useCallback, useEffect, useState } from 'react';
import { openSnackbar } from '../../../../features/SnackBar/snackBarSlice';
import { resetHealthScoreDetails } from '../../../../features/HealthScore/HealthScoreSlice';
import usePackageSelection from '../../../Accounts/components/PackageSelection/usePackageSelection';
import { addHealthScore, getHealthInsightsDetails, updateHealthScore } from '../../../../features/HealthScore/HealthScoreAction';
import { getFeaturesList } from '../../../../features';

const validationSchema = Yup.object().shape({
  healthScoreLabel: Yup.string().required('KPI Score Label is Required!'),
  healthScoreRules: Yup.array().of(
    Yup.object().shape({
      ruleType: Yup.string().required('rule type is required !'),
      targetMetric: Yup.string().required('target metric is required !'),
      threshold: Yup.array().of(Yup.number().test('positive', 'Value must be positive', (value) => value >= 0).required('Please enter threshold value')).test('checkOrder', 'Values must be in increasing order', (value) => {
        return !value || value.length < 2 || value[0] < value[1];
      }),
      rulePoints: Yup.string().required('rule points is required !'),
    }),
  ),
});

const initialRuleState = {
  healthScoreLabel: '',
  healthScoreRules: [{
    ruleType: '',
    ruleOption: '',
    targetMetric: '',
    threshold: [],
    rulePoints: '',
    isOpenGraph: false,
  }],
};
const useAddEditHeathInsights = () => {
  const [featureManagerListData, setFeatureManagerListData] = useState([]);
  const { id } = useParams();
  const { pathname } = useLocation();
  const isEditPage = pathname.includes('update');
  const isAddPage = pathname.includes('new');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const healthScoreDetails = useSelector((state) => state.healthInsightsDetails.healthInsightsDetails.healthScoreDetails);
  const featureManagerList = useSelector((state) => state.featureManagerList.featureManagerList);
  const { activePackage } = usePackageSelection();
  const packageId = activePackage && activePackage.packageId;

  const [healthScoreDetailsData, setHealthScoreDetailsData] = useState({
    healthScoreLabel: '',
    healthScoreRules: [],
  });

  useEffect(() => {
    const updatedArray = healthScoreDetails?.healthScoreRules?.map((ruleData) => {
      const obj = { ...ruleData };
      obj.threshold = JSON.parse(obj.threshold);
      return obj;
    });
    setHealthScoreDetailsData({
      healthScoreLabel: healthScoreDetails?.healthScoreLabel,
      healthScoreRules: updatedArray,
    });
  }, [healthScoreDetails]);

  useEffect(() => {
    if (isEditPage) {
      dispatch(getHealthInsightsDetails({ healthScoreId: id }));
    }
    return () => {
      dispatch(resetHealthScoreDetails());
    };
  }, [dispatch, id, isEditPage]);

  const handleSubmit = (values) => {
    dispatch(addHealthScore({ data: values, packageId })).then((res) => {
      const { payload } = res;
      if (res.error) {
        dispatch(openSnackbar({ open: true, message: payload, type: 'error' }));
      } else {
        navigate('/kpi-score-configuration');
        dispatch(openSnackbar({ open: true, message: 'KPI Score Created Successfully', type: 'success' }));
      }
    });
  };

  const handleEditSubmit = (values) => {
    const editData = {
      healthScoreId: id,
      healthScoreLabel: values.healthScoreLabel,
      healthScoreRules: values.healthScoreRules,
    };
    dispatch(updateHealthScore({ data: editData, packageId })).then((res) => {
      const { payload } = res;
      if (res.error) {
        dispatch(openSnackbar({ open: true, message: payload, type: 'error' }));
      } else {
        navigate('/kpi-score-configuration');
        dispatch(openSnackbar({ open: true, message: 'KPI Score Updated Successfully', type: 'success' }));
      }
    });
  };

  useEffect(() => {
    if (packageId) {
      dispatch(getFeaturesList({ packageId }));
    }
  }, [packageId]);

  useEffect(() => {
    if (featureManagerList.length) {
      setFeatureManagerListData(featureManagerList);
    }
  }, [featureManagerList]);

  const handleRuleChange = useCallback((index, selectedOption, setFieldValue) => {
    setFieldValue(`healthScoreRules[${index}].ruleType`, selectedOption);
    setFieldValue(`healthScoreRules[${index}].targetMetric`, '');
    setFieldValue(`healthScoreRules[${index}].threshold`, []);
    setFieldValue(`healthScoreRules[${index}].rulePoints`, '');
    setFieldValue(`healthScoreRules[${index}].ruleOption`, '');
  }, []);

  return {
    validationSchema,
    handleSubmit,
    isEditPage,
    isAddPage,
    healthScoreDetailsData,
    handleEditSubmit,
    featureManagerListData,
    handleRuleChange,
    initialRuleState,
  };
};

export default useAddEditHeathInsights;
