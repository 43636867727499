import {
  Box, Grid, Typography, Tooltip as MuiTooltip,
} from '@mui/material';
import moment from 'moment';
import { useLayoutEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Chart } from 'react-chartjs-2';
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  LineElement,
  PointElement,
  ArcElement,
  Title,
  Tooltip,
  Legend,
  Filler,
} from 'chart.js';
import { TrendingDown, TrendingUp, TrendingFlat } from '@mui/icons-material';
import { getMomentDate } from '../../../../utils/getDate';
import { DATE_FORMAT } from '../../../../utils/constants';
import { explainHealthScore } from '../../../../features/HealthScore/HealthScoreAction';
import { HealthScoreExplain } from '../../../HealthScore';
import useToggle from '../../../../hooks/useToggle';
import { CustomCircularProgress } from '../../../../components/CustomCircularProgress';
import OverviewCard from './OverviewCard';
import HealthScoreTrendsDialog from '../HealthScoreTrends/HealthScoreTrendsDialog';

function HealthScore({ healthScore = null }) {
  const normalizedValue = healthScore !== null ? Math.min(Math.max(healthScore, 0), 100) : 0;
  return (
    <Box>
      <CustomCircularProgress width={50} height={50} value={normalizedValue} />
    </Box>
  );
}

function HealthScoreTrendThumbnail({ trendsView, trendsData = [] }) {
  const chartData = {
    labels: trendsData.map((items) => items.startDate),
    datasets: [
      {
        type: 'line',
        label: 'KPI Score',
        data: trendsData.map((items) => items.data),
        fill: false,
        borderWidth: 1,
        borderColor: '#4482FF',
        pointStyle: 'circle', // Customize the point style
        pointBackgroundColor: '#4482FF', // Customize the point color
        pointRadius: 1, // Customize the point radius
      },
      {
        type: 'line',
        label: 'KPI Score',
        data: trendsData.map((items) => items.avgData),
        fill: false,
        borderWidth: 1,
        borderColor: '#f28e00',
        pointStyle: 'circle', // Customize the point style
        pointBackgroundColor: '#f28e00', // Customize the point color
        pointRadius: 1, // Customize the point radius
      },
      {
        type: 'bar',
        label: 'KPI Score (Bar)',
        data: trendsData.map((items) => items.data),
        backgroundColor: '#4482FF',
        borderColor: '#4482FF',
        borderWidth: 1,
      },
    ],
  };

  const options = {
    plugins: {
      legend: {
        display: false,
      },
      tooltip: {
        enabled: false,
        mode: 'index',
        external(context) {
          let tooltipEl = document.getElementById('chartjs-tooltip');
          if (!tooltipEl) {
            tooltipEl = document.createElement('div');
            tooltipEl.id = 'chartjs-tooltip';
            tooltipEl.innerHTML = '<table></table>';
            document.body.appendChild(tooltipEl);
          }

          const tooltipModel = context.tooltip;
          if (tooltipModel.opacity === 0) {
            tooltipEl.style.opacity = '0';
            return;
          }

          tooltipEl.classList.remove('below', 'no-transform');
          const { $context = {} } = tooltipModel;
          const { tooltipItems = [] } = $context;
          if (tooltipItems && tooltipItems.length) {
            const dateObj = tooltipItems?.[0]?.label ? getMomentDate(tooltipItems?.[0]?.label) : '';
            const label = dateObj.format(
              trendsView === 'month' ? DATE_FORMAT.MONTH_DATE_GRAPH : DATE_FORMAT.WEEK_DATE_GRAPH,
            );
            const average = tooltipItems?.[1]?.raw;
            const actual = tooltipItems?.[0]?.raw;
            const innerHTML = `
                <div style="width: 150px; background-color: rgba(0, 0, 0, 0.7); border-radius: 5px; box-shadow: 0 0 5px rgba(0, 0, 0, 0.5); padding: 3px;">
                    <div style="padding: 3px; background-color: transparent; border-top-left-radius: 5px; border-top-right-radius: 5px;">
                        <span style="color: white; font-size: 15px;">${label}</span>
                    </div>
                    <hr style="margin: 0; border-color: white;">
                    <div style="padding: 3px;">
                    <p style="color: white; margin-left: 4px; font-size: 14px; display: flex; align-items: center; gap: 5px">
                    <span style="display: inline-block; width: 10px; height: 10px; border-radius: 50%; background-color: #4482FF;"></span>
                    Actual : ${actual}
                  </p>
                  <p style="color: white; margin-left: 4px; font-size: 14px; display: flex; align-items: center; gap: 5px">
                    <span style="display: inline-block; width: 10px; height: 10px; border-radius: 50%; background-color: #f28e00;"></span>
                    Average : ${average}
                  </p>
                    </div>
                </div>`;

            tooltipEl.querySelector('table').innerHTML = innerHTML;
          }

          const position = context.chart.canvas.getBoundingClientRect();

          tooltipEl.style.opacity = '1';
          tooltipEl.style.position = 'absolute';

          const tooltipWidth = tooltipEl.offsetWidth;
          let left = position.left + window.pageXOffset + tooltipModel.caretX;
          if (left + tooltipWidth > position.right) {
            left -= left + tooltipWidth - position.right;
          }

          tooltipEl.style.left = `${left}px`;

          tooltipEl.style.top = `${position.top + window.pageYOffset + tooltipModel.caretY}px`;
          tooltipEl.style.padding = `${tooltipModel.padding}px ${tooltipModel.padding}px`;
          tooltipEl.style.pointerEvents = 'none';
        },
      },
    },
    scales: {
      x: {
        display: false,
      },
      y: {
        display: false,
        suggestedMin: 0,
        suggestedMax: 100,
      },
    },
  };

  useLayoutEffect(() => {
    ChartJS.register(
      CategoryScale,
      LinearScale,
      BarElement,
      LineElement,
      PointElement,
      ArcElement,
      Title,
      Tooltip,
      Legend,
      Filler,
    );

    return () => {
      ChartJS.unregister(
        CategoryScale,
        LinearScale,
        BarElement,
        LineElement,
        PointElement,
        ArcElement,
        Title,
        Tooltip,
        Legend,
        Filler,
      );
    };
  }, [trendsData, chartData, options]);

  return (
    <Box sx={{ width: 110, height: 60 }}>
      <Chart type="bar" data={chartData} options={options} />
    </Box>
  );
}

function KpiScoreOverview(props) {
  const {
    orgId, packageId, generalDetail, permissions, primaryScoreObj, tenantId, accountOrgId, isShare,
  } = props;

  const dispatch = useDispatch();
  const { data, loading } = generalDetail;
  const healthScores = useSelector((state) => state.accountHealthScore?.accountsLicense);

  const [open, setOpen] = useToggle();
  const [openGraph, setOpenGraph] = useToggle();
  const [selectedHealthScore, setSelectedHealthScore] = useState(null);
  const [selectedKpiTrend, setSelectedKpiTrend] = useState({ title: '', view: '' });

  // KPI Score
  const handleClickScore = () => {
    const primaryHealthScore = healthScores?.find((score) => score.isPrimary === true);
    const primaryHealthScoreId = primaryHealthScore?.healthScoreId ?? null;
    setOpen();
    setSelectedHealthScore(data?.healthScore);
    dispatch(explainHealthScore({ orgId, packageId, healthScoreId: primaryHealthScoreId }));
  };

  const kpiInfo = `${moment(data?.healthScoreRange?.startDate).format("DD MMM 'YY")} to ${moment(
    data?.healthScoreRange?.endDate,
  ).format("DD MMM 'YY")}`;

  // KPI Trend
  const trendIcon = (type, trendValue) => {
    if (type === 'rising') {
      return (
        <MuiTooltip title="Trending">
          <Typography
            sx={{
              display: 'flex',
              flexDirection: 'row',
              color: 'green',
              alignItems: 'center',
            }}
          >
            <TrendingUp />
            {trendValue ? <Typography sx={{ fontSize: 13 }}>{` +${trendValue}`}</Typography> : null}
          </Typography>
        </MuiTooltip>
      );
    }
    if (type === 'falling') {
      return (
        <MuiTooltip title="Declining">
          <Typography
            sx={{
              display: 'flex',
              flexDirection: 'row',
              color: 'red',
              alignItems: 'center',
            }}
          >
            <TrendingDown />
            {trendValue ? <Typography sx={{ fontSize: 13 }}>{` ${trendValue}`}</Typography> : null}
          </Typography>
        </MuiTooltip>
      );
    }
    if (type === 'stable') {
      return (
        <MuiTooltip title="stable">
          <Typography
            sx={{
              display: 'flex',
              flexDirection: 'row',
              color: 'gray',
              alignItems: 'center',
            }}
          >
            <TrendingFlat />
          </Typography>
        </MuiTooltip>
      );
    }
    return null;
  };

  const handleClickTrend = (view) => {
    setOpenGraph();
    const title = view === 'month' ? 'Monthly Trend' : 'Weekly Trend';
    setSelectedKpiTrend({ title, view });
  };

  return (
    <>
      <Grid
        item
        xs={12}
        sx={{
          display: 'flex',
          flexDirection: 'row',
          justifyContent: 'space-between',
          gap: 2,
        }}
      >
        <OverviewCard
          loading={loading}
          data={data}
          title="KPI Score"
          thumbnail={<HealthScore healthScore={data?.healthScore} />}
          info={kpiInfo}
          handleClick={handleClickScore}
        />

        <OverviewCard
          loading={loading}
          data={data}
          title="Monthly Trend"
          thumbnail={<HealthScoreTrendThumbnail trendsView="month" trendsData={data?.trendsData ?? []} />}
          info={trendIcon(data?.healthScoreTrend ?? '', data?.trendValue ?? 0)}
          handleClick={() => handleClickTrend('month')}
        />

        <OverviewCard
          loading={loading}
          data={data}
          title="Weekly Trend"
          thumbnail={<HealthScoreTrendThumbnail trendsView="week" trendsData={data?.weeklyTrendsData ?? []} />}
          info={trendIcon(data?.weeklyTrend ?? '', data?.weeklyTrendValue ?? 0)}
          handleClick={() => handleClickTrend('week')}
        />
      </Grid>

      <HealthScoreExplain
        open={open}
        setOpen={setOpen}
        permissions={permissions}
        healthScore={selectedHealthScore}
      />
      <HealthScoreTrendsDialog
        orgId={orgId}
        isShare={isShare}
        pkgId={packageId}
        tenantId={tenantId}
        accountOrgId={accountOrgId}
        title={selectedKpiTrend.title}
        view={selectedKpiTrend.view}
        primaryScoreObj={primaryScoreObj}
        openGraph={openGraph}
        setOpenGraph={setOpenGraph}
      />
    </>
  );
}

export default KpiScoreOverview;
