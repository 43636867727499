import {
  useEffect, useState, useRef, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Box, Button, Grid, Stack,
  IconButton,
  TextField,
  Tooltip,
  Typography,
} from '@mui/material';
import ShareIcon from '@mui/icons-material/Share';
import { Masonry } from '@mui/lab';
import { useLocation, useSearchParams } from 'react-router-dom';
import { FeatureParameter } from '../../modules/Accounts/components/FeatureParameter';
import { SelectBox } from '../../components/FormComponents';
import { ConfirmDialogBox } from '../../components/CustomDialogBox';
import palette from '../../theme/palette';
import { EventTimeLine } from '../../modules/Accounts/components/EventTimeline';
import { MutedText } from '../../components/CustomTypography';
import useStyles from '../../hooks/useStyles';
import { PackageHeader } from '../../components/CustomHeading';
import {
  getActiveUsers,
  getCustomObjects,
  getCustomObjectPinningData,
  getGeneralDetails,
  getPackageList,
  getAllRemarks,
  getAccountPackageList,
} from '../../features';
import useActiveUsers from '../../modules/Accounts/components/ActiveUsers/useActiveUsers';
import {
  ActiveUsers,
  TopCustomObject,
  EngagementSection,
  AccountHealthScore,
  PinnedFeatureUsage,
  GeneralDetailSection,
  KpiScoreOverview,
} from '../../modules/Accounts';
import useAccountDetail from './useAccountDetail';
import useToggle from '../../hooks/useToggle';
import usePackageSelection from '../../modules/Accounts/components/PackageSelection/usePackageSelection';
import { TopFeatures } from '../TopFeatures';
import { updateAccountVisibility } from '../../features/AccountList/accountListAction';
import styles from '../../layouts/pages/style';
import { openSnackbar } from '../../features/SnackBar/snackBarSlice';
import { resetAccountsList } from '../../features/AccountList/accountListSlice';
import { getCustomAlertDetails } from '../../features/CustomAlert/customAlertAction';

function AccountDetail(props) {
  const {
    isShareableScreens, permissions, userInfo, tenantId, isSalesforce, accountOrgId,
  } = props;

  const [searchParams] = useSearchParams();
  const location = useLocation();
  const dispatch = useDispatch();
  const orgId = searchParams.get('orgId');
  const pkgId = searchParams.get('pkgId');
  const accountId = searchParams.get('accountId');
  const licenseId = searchParams.get('licenseId');
  const accountState = location.state;
  const [isShare, setIsShare] = useState(isShareableScreens);
  const [accountPackage, setAccountPackage] = useState(pkgId);
  const [refreshUsers, setRefreshUsers] = useToggle();
  const { activePackage } = usePackageSelection({ pkgId: accountPackage, isShare });
  const {
    customObject, generalDetail, customObjectPinningData, visibility, setVisibility,
  } = useAccountDetail();
  const [openDialog, setOpenDialog] = useState();
  const { activeUsers } = useActiveUsers();
  const urlRef = useRef(null);
  const {
    monthObject, weekObject, error, loading,
  } = activeUsers;

  useEffect(() => {
    setIsShare(isShareableScreens);
  }, [isShareableScreens]);

  useEffect(() => {
    if (!activePackage) {
      dispatch(getPackageList({ isShare, tenantId }));
    }
  }, []);

  useEffect(() => {
    if (isSalesforce && accountOrgId) {
      dispatch(getAccountPackageList({
        isShare, accountOrgId, accountId, licenseId,
      }));
    }
  }, [isSalesforce, accountOrgId, accountId, licenseId]);

  useEffect(() => {
    if (accountPackage || activePackage) {
      dispatch(getCustomObjects({
        orgId, pkgId: (accountPackage || activePackage.packageId), isShare, tenantId, view: '30days', accountOrgId,
      }));
    }
    dispatch(getCustomAlertDetails({
      pkgId: (accountPackage || activePackage.packageId), isShare, tenantId, accountOrgId,
    }));
    dispatch(getCustomObjectPinningData({
      isShare, tenantId, packageId: (accountPackage || activePackage.packageId), accountOrgId,
    }));
    dispatch(getGeneralDetails({
      orgId, isShare, pkgId: accountPackage, tenantId, accountOrgId,
    }));
    dispatch(getAllRemarks({
      orgId, isShare, tenantId, packageId: accountPackage, accountOrgId,
    }));
  }, [activePackage?.packageId, isShare, tenantId, accountPackage, orgId, accountOrgId]);

  useEffect(() => {
    if (accountPackage || activePackage) {
      dispatch(getActiveUsers({
        page: 'account',
        orgId,
        packageId: (accountPackage || activePackage.packageId),
        isShare,
        tenantId,
        accountOrgId,
      }));
    }
  }, [refreshUsers, activePackage, isShare, tenantId, accountPackage, accountOrgId]);

  const customFilterData = useSelector((state) => state.getCustomAlertDetails.data);
  const accountPackageListInfo = useSelector((state) => state.accountPackageList.data);
  const [accountName, setAccountName] = useState('');
  const [trendData, setTrendData] = useState({ healthScoreTrend: '', trendValue: '' });
  const [primaryScoreObj, setPrimaryScoreObj] = useState(null);

  useEffect(() => {
    dispatch(getGeneralDetails({
      orgId, isShare, pkgId: accountPackage, tenantId, accountOrgId,
    }));
  }, [activePackage?.packageId, isShare, tenantId, accountPackage, orgId, accountOrgId, primaryScoreObj]);

  useEffect(() => {
    if (!generalDetail.loading) {
      setAccountName(accountState?.accountName || generalDetail?.data?.accountName || orgId);
      const data = generalDetail?.data;
      const trendInfo = { healthScoreTrend: (customFilterData?.[0]?.view === 'weekly' ? (data?.weeklyTrend) : data?.healthScoreTrend), trendValue: (customFilterData?.[0]?.view === 'weekly' ? (data?.weeklyTrendValue) : data?.trendValue) };
      setTrendData(trendInfo);
    }
  }, [accountState?.accountName, generalDetail?.data, !generalDetail.loading]);

  const handleVisibility = () => {
    dispatch(updateAccountVisibility({ orgId, packageId: accountPackage, visibility: !visibility })).then((res) => {
      if (res?.payload?.status === 'SUCCESS') {
        setVisibility(!visibility);
        dispatch(resetAccountsList());
      }
      return null;
    });
  };

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
  };

  const handleCopyToClipboard = () => {
    // Select the text in the input field
    const input = urlRef.current.querySelector('input');
    if (input) {
      input.select();
      document.execCommand('copy');
      // Deselect the text
      window.getSelection().removeAllRanges();
      dispatch(
        openSnackbar({
          open: true,
          message: 'Public URL copied to clipboard',
          type: 'success',
        }),
      );
      setOpenDialog(false);
    }
  };

  const classes = useStyles(styles)();

  const accountPackageList = useMemo(() => {
    const result = (accountPackageListInfo || []).map((accPkg) => ({
      id: accPkg.packageId,
      name: accPkg.packageLabel,
      value: accPkg.packageId,
    }));
    return result;
  }, [accountPackageListInfo]);

  const accountPackageDetail = useMemo(() => {
    let result = {};
    (accountPackageListInfo || []).forEach((accPkg) => {
      if (accPkg.packageId === pkgId) {
        result = accPkg;
      }
    });
    return result;
  }, [accountPackageListInfo]);

  return (
    <Grid container gap={2}>
      <Grid item xs={12} className={classes.cardStyles}>
        <Stack direction="row" alignItems="baseline" justifyContent="space-between">
          <Box>
            <Stack flexDirection="row" gap={2}>
              <PackageHeader trendData={trendData} heading={accountName} noPackage={isSalesforce} />
              {isSalesforce && accountPackageList?.length > 1 ? (
                <Stack width={250}>
                  <SelectBox
                    title="Account Package"
                    value={accountPackage}
                    data={accountPackageList}
                    onChange={(e) => {
                      setAccountPackage(e.target.value);
                    }}
                  />
                </Stack>
              ) : isSalesforce && (
                <Stack
                  alignItems="center"
                  sx={{
                    background: (theme) => theme.palette.grey[200],
                    width: 'max-content',
                    p: '5px 15px',
                    borderRadius: '6px',
                  }}
                >
                  <Typography variant="h4">
                    {accountPackageDetail?.packageLabel}
                  </Typography>
                </Stack>
              )}
            </Stack>
            <MutedText text="Account" />
          </Box>

          {!isShareableScreens && (
          <Stack direction="row" spacing={1} alignItems="center" justifyContent="space-between">
            <ConfirmDialogBox
              title="Public URL"
              message={(
                <TextField
                  ref={urlRef}
                  value={`${window.location.href}&isShare=true&tenantId=${userInfo.tenantId}`}
                  readOnly
                  style={{
                    width: '100%',
                  }}
                />
                     )}
              openDialog={openDialog}
              onClose={handleCloseDialog}
              onConfirm={handleCopyToClipboard}
              confirmText="Copy"
            />
            <Tooltip title="Copy public page link">
              <IconButton
                color="primary"
                variant="outlined"
                onClick={handleOpenDialog}
                style={{ color: palette.primary.mainColor }}
              >
                <ShareIcon />
              </IconButton>
            </Tooltip>

            {permissions.write && (
            <Button variant="outlined" onClick={handleVisibility}>
              {visibility ? 'Exclude From Reports' : 'Include In Reports'}
            </Button>
            )}
          </Stack>
          )}
        </Stack>
      </Grid>

      <KpiScoreOverview
        orgId={orgId}
        packageId={accountPackage}
        generalDetail={generalDetail}
        permissions={permissions}
        primaryScoreObj={primaryScoreObj}
        tenantId={tenantId}
        accountOrgId={accountOrgId}
        isShare={isShareableScreens}
      />

      <Grid item xs={12}>
        <Masonry
          columns={{ sm: 1, md: 2 }}
          spacing={2}
          sx={{ width: 'auto' }}
        >
          <Box>
            <GeneralDetailSection permissions={permissions} generalDetail={generalDetail} isShare={isShareableScreens} />
          </Box>
          <Box>
            <EngagementSection monthObject={monthObject} weekObject={weekObject} />
          </Box>
          <Box>
            <AccountHealthScore
              isShare={isShareableScreens}
              tenantId={tenantId}
              accountOrgId={accountOrgId}
              permissions={permissions}
              setPrimaryScoreObj={setPrimaryScoreObj}
            />
          </Box>
          <FeatureParameter orgId={orgId} packageId={accountPackage} />
          <Box>
            <ActiveUsers
              title="Monthly Active Users"
              resultSet={monthObject}
              error={error}
              loading={loading}
              page="account"
              more={!isShareableScreens}
              accountName={accountName}
              orgId={orgId}
              setRefreshUsers={setRefreshUsers}
            />
          </Box>
          <Box>
            <PinnedFeatureUsage orgId={orgId} pkgId={accountPackage} activePackage={activePackage} accountName={accountName} isShare={isShareableScreens} tenantId={tenantId} accountOrgId={accountOrgId} />
          </Box>
          <Box>
            <TopFeatures activePackage={activePackage} orgId={orgId} pkgId={accountPackage} accountName={accountName} isShare={isShareableScreens} tenantId={tenantId} accountOrgId={accountOrgId} />
          </Box>
          <Box>
            <TopCustomObject
              moreButton={!isShareableScreens}
              customObject={customObject}
              customObjectPinningData={customObjectPinningData}
              pageSize={5}
              orgId={orgId}
              pkgId={accountPackage}
              accountName={accountName}
              isShare={isShareableScreens}
              permissions={permissions}
            />
          </Box>
        </Masonry>
      </Grid>
      <Grid item xs={12}>
        <EventTimeLine
          orgId={orgId}
          pkgId={accountPackage}
          activePackage={activePackage}
          isShare={isShareableScreens}
          tenantId={tenantId}
          accountOrgId={accountOrgId}
        />
      </Grid>
    </Grid>
  );
}

export default AccountDetail;
