import { FormHelperText, TextField, FormControl } from '@mui/material';

export default function TextFieldWithError({
  error, name, label, type, onChange, InputProps, touched, value, multiline, defaultValue,
}) {
  return (
    <FormControl fullWidth sx={{ position: 'relative', m: '8px 0 18px' }}>
      <TextField
        touched={touched}
        error={error}
        name={name}
        label={label}
        multiline={multiline}
        type={type}
        variant="outlined"
        onChange={onChange}
        fullWidth
        InputProps={InputProps}
        value={value}
        rows={4}
        defaultValue={defaultValue}
      />
      <FormHelperText error={error} sx={{ position: 'absolute', bottom: '-1.5em' }}>
        {error && error}
      </FormHelperText>
    </FormControl>
  );
}
