import moment from 'moment';
import { stringify } from '../../utils/methods';

const accountStatus = {
  ACTIVE: 'Active',
  TRIAL: 'Trial',
  EXPIRED: 'Expired',
  CHURNED: 'Churned',
  SUSPENDED: 'Suspended',
  ACTIVE_TRIAL: 'Active & Trial',
};

const featureUsageModeConst = {
  USE: 'Used',
  DONT_USE: 'Not Used',
};

const marks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 10,
    label: '10',
  },
  {
    value: 50,
    label: '50',
  },
  {
    value: 100,
    label: '100',
  },
  {
    value: 150,
    label: '100+',
  },
];

const healthScoreMarks = [
  {
    value: 0,
    label: '0',
  },
  {
    value: 20,
    label: '20',
  },
  {
    value: 40,
    label: '40',
  },
  {
    value: 60,
    label: '60',
  },
  {
    value: 80,
    label: '80',
  },
  {
    value: 100,
    label: '100',
  },
];

const defaultFilterValue = '- all -';

const defaultView = { id: defaultFilterValue, value: defaultFilterValue, name: 'All Accounts' };

const initialFilterValue = {
  licenceAssignment: defaultFilterValue,
  licenceStatus: defaultFilterValue,
  packageVersion: defaultFilterValue,
  salesforceInstance: defaultFilterValue,
  accountVisibility: defaultFilterValue,
  country: defaultFilterValue,
  language: defaultFilterValue,
  organisationEdition: defaultFilterValue,
  featureUsageFilter: defaultFilterValue,
  featureUsageMode: defaultFilterValue,
  activationStartDate: null,
  activationEndDate: null,
  renewalStartDate: null,
  renewalEndDate: null,
  searchQuery: '',
  range: [0, 150],
  healthScoreRange: [0, 100],
  licenceAssignmentDash: [0, 1250],
  healthScoreTrend: defaultFilterValue,
  toggleAdvanceFilter: false,
};

const hasDefaultFilters = (selectedFilters) => {
  const res = Object.keys(initialFilterValue).some(
    (key) => initialFilterValue[key] !== selectedFilters[key],
  );
  return !res;
};

const hasAdvanceFilters = (selectedFilters) => {
  const advanceFilters = [
    'activationStartDate',
    'activationEndDate',
    'renewalStartDate',
    'renewalEndDate',
    'searchQuery',
    'range',
    'healthScoreRange',
    'licenceAssignmentDash',
    'healthScoreTrend',
  ];
  const res = advanceFilters.some(
    (key) => stringify(initialFilterValue[key]) !== stringify(selectedFilters[key]),
  );
  return res;
};

const compareVersions = (versionA, versionB) => {
  const partsA = versionA.split('.').map(Number);
  const partsB = versionB.split('.').map(Number);

  for (let i = 0; i < Math.max(partsA.length, partsB.length); i++) {
    const partA = partsA[i] || 0;
    const partB = partsB[i] || 0;

    if (partA !== partB) {
      return partA - partB;
    }
  }

  return 0;
};

const filterByName = (array) => {
  const sortedOptions = array.sort((a, b) => a.name.localeCompare(b.name)).filter(({ name }, index, arr) => index === 0 || name !== arr.at(index - 1).name);
  return sortedOptions;
};

const regionNamesByLanguageCode = new Intl.DisplayNames(['en'], { type: 'language' });
const regionNamesByCountryCode = new Intl.DisplayNames(['en'], { type: 'region' });
const today = moment().startOf('day');
const end = today.clone().add(13, 'days');

const fixedWatchList = [
  {
    id: 'expiringSoon',
    value: 'expiringSoon',
    name: 'Expiring Soon',
  },
  {
    id: 'lowLicenseUtilization',
    value: 'lowLicenseUtilization',
    name: 'Low License Utilization',
  },
  {
    id: 'lowHealthScore',
    value: 'lowHealthScore',
    name: 'Low KPI Score',
  },
  {
    id: 'decliningHealthScore',
    value: 'decliningHealthScore',
    name: 'Declining KPI Score',
  },
];

const fixedWatchListData = [
  {
    id: 'expiringSoon',
    name: 'Expiring Soon',
    filter: {
      licenceStatus: 'Active',
      accountVisibility: true,
      renewalStartDate: today.toDate(),
      renewalEndDate: end.toDate(),
      toggleAdvanceFilter: true,
    },
  },
  {
    id: 'lowLicenseUtilization',
    name: 'Low License Utilization',
    filter: {
      licenceStatus: 'Active',
      accountVisibility: true,
      licenceAssignment: 'Less than 25%',
    },
  },
  {
    id: 'lowHealthScore',
    name: 'Low KPI Score',
    filter: {
      licenceStatus: 'Active',
      accountVisibility: true,
      healthScoreRange: [0, 30],
      toggleAdvanceFilter: true,
    },
  },
  {
    id: 'decliningHealthScore',
    name: 'Declining KPI Score',
    filter: {
      licenceStatus: 'Active',
      accountVisibility: true,
      healthScoreTrend: 'falling',
      toggleAdvanceFilter: true,
    },
  },
];

const deleteProtectedView = ['- all -', 'expiringSoon', 'lowLicenseUtilization', 'lowHealthScore', 'decliningHealthScore'];

export {
  accountStatus,
  featureUsageModeConst,
  marks,
  healthScoreMarks,
  defaultView,
  defaultFilterValue,
  initialFilterValue,
  hasDefaultFilters,
  hasAdvanceFilters,
  regionNamesByLanguageCode,
  regionNamesByCountryCode,
  compareVersions,
  filterByName,
  fixedWatchList,
  fixedWatchListData,
  deleteProtectedView,
};
