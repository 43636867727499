import { Typography, styled } from '@mui/material';

const TypographyStyle = styled(Typography)(({ theme }) => ({
  fontSize: 14,
  color: theme.palette.secondary.mainColor,
  cursor: 'pointer',
  textDecoration: 'underline',
}));

function ClickableCell({ value, onClick = () => null }) {
  return (
    <TypographyStyle onClick={onClick}>{value}</TypographyStyle>
  );
}

export default ClickableCell;
