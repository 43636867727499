import {
  Grid,
  Button,
  Box,
} from '@mui/material';
import ErrorOutlineIcon from '@mui/icons-material/ErrorOutline';
import { InfoChip } from '../../components/CustomChip';
import { BUTTONS, TITLES } from '../../utils/constants';
import { PackageHeader } from '../../components/CustomHeading';
import useStyles from '../../hooks/useStyles';
import styles from '../../layouts/pages/style';
import useHealthScoreConfig from './useHealthScoreConfig';
import HealthScoreList from '../../modules/HealthScore/components/HealthScoreList/HealthScoreList';

export default function HealthScoreConfig() {
  const {
    healthScoreList,
    loading,
    data,
    error,
    handleAddNewScore,
    actionType,
    HealthScoreColumns,
    openDialog,
    handleClose,
    handleDeleteConfirm,
    handleMakePrimaryConfirm,
  } = useHealthScoreConfig();

  const classes = useStyles(styles)();

  return (
    <Grid container gap={2}>
      <Grid item xs={12} className={classes.cardStyles}>
        <Box className={classes.headerStyles}>
          <PackageHeader heading={TITLES.HEALTH_SCORE_CONFIGURATION} />
          <Button
            disabled={healthScoreList && healthScoreList.length >= 5}
            variant="outlined"
            onClick={handleAddNewScore}
          >
            {BUTTONS.NEW_HEALTH_SCORE}
          </Button>
        </Box>
      </Grid>

      {healthScoreList && healthScoreList.length >= 5 && (
        <Grid item xs={12}>
          <InfoChip
            type="banner"
            icon={<ErrorOutlineIcon color="primary" />}
            label="You have Created Maximum Number of KPI Score."
          />
        </Grid>
      )}

      <Grid item xs={12} className={classes.tableStyles}>
        <HealthScoreList
          columns={HealthScoreColumns}
          openDialog={openDialog}
          handleClose={handleClose}
          handleMakePrimaryConfirm={handleMakePrimaryConfirm}
          handleDeleteConfirm={handleDeleteConfirm}
          rowId="healthScoreId"
          loading={loading}
          data={data}
          error={error}
          actionType={actionType}
          footer
        />
      </Grid>
    </Grid>
  );
}
