import { useLocation } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { useEffect } from 'react';
import { Button } from '@mui/material';
import { ConfirmDialogBox } from '../../../../components/CustomDialogBox';
import useToggle from '../../../../hooks/useToggle';
import { HealthScoreExplain } from '../../../HealthScore';
import DashboardCards from '../../../../components/DashboardCard/DashboardCards';
import { getAccountHealthScore } from '../../../../features/AccountHealthScore/accountHealthScoreAction';
import usePackageSelection from '../PackageSelection/usePackageSelection';
import useAccountHealthScore from './useAccountHealthScore';
import AccountHealthScoreTable from './AccountHealthScoreTable';

export default function AccountHealthScore(props) {
  const {
    isShare, permissions, tenantId, accountOrgId, setPrimaryScoreObj,
  } = props;
  const dispatch = useDispatch();
  const location = useLocation();

  const searchParams = new URLSearchParams(location.search);
  const orgId = searchParams.get('orgId');
  const pkgId = searchParams.get('pkgId');
  const { activePackage } = usePackageSelection({ orgId, isShare });

  const [refreshHealthScore, setRefreshHealthScore] = useToggle();

  useEffect(() => {
    const packageId = pkgId || (activePackage && activePackage.packageId);
    if (packageId && orgId) {
      dispatch(getAccountHealthScore({
        packageId, orgId, isShare, tenantId, accountOrgId,
      }));
    }
  }, [activePackage?.packageId, orgId, refreshHealthScore, isShare, tenantId, pkgId, accountOrgId]);

  const {
    open, setOpen, openCnfDialog, setOpenCnfDialog, cnfDialogData, selectedHealthScore, accountHealthScore, columns, handleClickSettings, handleMakePrimary, handleClickReset, error, loading,
  } = useAccountHealthScore({
    orgId, packageId: pkgId || activePackage?.packageId, isShare, tenantId, accountOrgId, setPrimaryScoreObj, permissions,
  });
  return (
    <>
      <DashboardCards
        title="KPI Scores"
        showButton={(permissions.write && !isShare)}
        buttonText="Settings"
        onClick={handleClickSettings}
        action={(permissions.write && !isShare) && (
          <Button variant="outlined" onClick={handleClickReset}>
            Reset
          </Button>
        )}
      >
        <AccountHealthScoreTable
          data={accountHealthScore?.accountsLicense}
          columns={columns}
          error={error}
          loading={loading}
          refresh={setRefreshHealthScore}
          message="No Rows"
        />
      </DashboardCards>
      <HealthScoreExplain open={open} setOpen={setOpen} isShare={isShare} permissions={permissions} healthScore={selectedHealthScore} />
      <ConfirmDialogBox
        title={cnfDialogData.title}
        message={cnfDialogData.message}
        openDialog={openCnfDialog}
        onClose={setOpenCnfDialog}
        onConfirm={handleMakePrimary}
      />
    </>
  );
}
