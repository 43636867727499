import { Formik, Form, FieldArray } from 'formik';
import {
  Button, Box, Tooltip, Grid, Stack, Typography, FormControl, TextField,
} from '@mui/material';
import { useDispatch } from 'react-redux';
import { openSnackbar } from '../../../../features/SnackBar/snackBarSlice';
import { toolTipText } from '../../../../utils/toolTipText';
import RuleComponent from '../RuleComponent/RuleComponent';
import palette from '../../../../theme/palette';

function RuleForm({
  initialValues,
  validationSchema,
  onSubmit,
  handleRuleChange,
  data,
  featureManagerListData,
  isEditPage,
  initialRuleState,
  isCopyClick,
}) {
  const dispatch = useDispatch();

  return (
    <Formik initialValues={initialValues} enableReinitialize validationSchema={validationSchema} onSubmit={onSubmit}>
      {({
        values, touched, errors, handleChange, handleBlur, setFieldValue, isValid,
      }) => (
        <Form>
          <Grid style={{ backgroundColor: 'white', padding: 15 }}>
            <Stack direction="row" spacing={2} justifyContent="space-between" alignItems="center">
              <FormControl fullWidth>
                <TextField
                  name="healthScoreLabel"
                  placeholder="KPI Score Label"
                  variant="outlined"
                  value={values?.healthScoreLabel}
                  onChange={handleChange}
                  focused={isCopyClick}
                  autoFocus={isCopyClick}
                />
              </FormControl>
            </Stack>
          </Grid>
          <FieldArray name="healthScoreRules">
            {({ push, remove, insert }) => (
              <Box>
                <Grid style={{ backgroundColor: 'white', padding: 15 }}>
                  <Stack direction="row" spacing={2} justifyContent="start" alignItems="center">
                    <Typography variant="h6">Rules</Typography>
                    <Tooltip title={values.healthScoreRules?.length >= 10 && toolTipText.healthInsights.ADD_RULE_FAIL}>
                      <span>
                        <Button
                          type="button"
                          variant="outlined"
                          onClick={() => (values?.healthScoreRules?.length < 10 ? push(initialRuleState.healthScoreRules[0]) : dispatch(openSnackbar({ open: true, message: toolTipText.healthInsights.ADD_RULE_FAIL, type: 'error' })))}
                        >
                          Add Rule
                        </Button>
                      </span>
                    </Tooltip>
                  </Stack>
                </Grid>
                {values.healthScoreRules?.map((ruleData, index) => (
                  <RuleComponent
                    key={index}
                    index={index}
                    ruleData={ruleData}
                    values={values}
                    touched={touched}
                    errors={errors}
                    handleChange={handleChange}
                    handleBlur={handleBlur}
                    handleRemove={remove}
                    handleRuleChange={handleRuleChange}
                    setFieldValue={setFieldValue}
                    featureManagerListData={featureManagerListData}
                    isEditPage={isEditPage}
                    isValid={isValid}
                    insert={insert}
                  />
                ))}
              </Box>
            )}
          </FieldArray>
          <Box display="flex" justifyContent="center" paddingTop={1}>
            <Tooltip title={data?.healthScoreLabel?.length === 0 && toolTipText.healthInsights.HEALTH_SCORE_LABEL}>
              <span>
                <Button
                  type="submit"
                  variant="contained"
                  disabled={!isValid}
                  sx={{
                    mt: 1,
                    mr: 1,
                    backgroundColor: !(!isValid) && `${palette.primary.mainColor} !important`,
                    color: !(!isValid) && `${palette.grey[0]} !important`,
                  }}
                >
                  Save All
                </Button>
              </span>
            </Tooltip>
          </Box>
        </Form>
      )}
    </Formik>
  );
}

export default RuleForm;
