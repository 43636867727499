import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import {
  Box, Button, Grid, IconButton, Stack, Tooltip, Typography, useMediaQuery,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import DriveFileRenameOutlineRoundedIcon from '@mui/icons-material/DriveFileRenameOutlineRounded';
import AutoAwesomeIcon from '@mui/icons-material/AutoAwesome';
import moment from 'moment';
import { openSnackbar } from '../../features/SnackBar/snackBarSlice';
import {
  deleteHealthScore,
  getHealthInsightsList,
  updateHealthScore,
} from '../../features/HealthScore/HealthScoreAction';
import usePackageSelection from '../../modules/Accounts/components/PackageSelection/usePackageSelection';
import { resetAccountsList } from '../../features/AccountList/accountListSlice';

export function LabelCell(props) {
  const { isPrimary, label } = props;
  const isSmallScreen = useMediaQuery('(max-width:600px)');
  return isSmallScreen ? (
    <Stack direction={isSmallScreen ? 'column' : 'row'}>
      <Box width="100%" display="flex" alignItems="center">
        <Tooltip title={label}>
          <Typography
            sx={{
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              marginRight: '8px',
            }}
          >
            {label}
          </Typography>
        </Tooltip>
        {isPrimary && (
          <Typography
            sx={{
              flexShrink: 0,
              borderRadius: '5px',
              backgroundColor: '#D8FDD7',
              padding: '0px 6px',
              color: '#35B430',
              ...(isSmallScreen && {
                fontSize: '12px',
              }),
            }}
          >
            {isSmallScreen ? 'Primary' : 'Primary KPI Score'}
          </Typography>
        )}
      </Box>
    </Stack>
  ) : (
    <Box width="100%">
      <Box display="flex" alignItems="center" width="100%">
        <Tooltip title={label}>
          <Typography
            sx={{
              width: 'auto',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              marginRight: '8px',
            }}
          >
            {label}
          </Typography>
        </Tooltip>
        {isPrimary && (
          <Typography
            style={{
              borderRadius: '5px',
              backgroundColor: '#D8FDD7',
              padding: '0px 6px',
              color: '#35B430',
            }}
          >
            Primary KPI Score
          </Typography>
        )}
      </Box>
    </Box>
  );
}

function ActionCell({ actionCellData, params, healthScoreList }) {
  return (
    <Grid container spacing={2} justifyContent="center">
      {actionCellData.length
        && actionCellData.map((obj) => {
          const Icon = obj.icon;
          const isDisabled = (obj.id !== 'edit' && params.row.primaryHealthScore) || (obj.id === 'copy' && healthScoreList?.length >= 5);
          return (
            <Grid item>
              {obj.id === 'make_primary' ? (
                <Button
                  key={obj.id}
                  variant="outlined"
                  size="small"
                  disabled={isDisabled}
                  onClick={() => obj.handleFunction(params.row.healthScoreId)}
                >
                  {obj.name}
                </Button>
              ) : (
                <Tooltip title={obj.name}>
                  <IconButton
                    variant="outlined"
                    disabled={isDisabled}
                    onClick={() => obj.handleFunction(params.row.healthScoreId)}
                  >
                    <Icon color={isDisabled ? 'disabled' : 'primary'} fontSize="small" />
                  </IconButton>
                </Tooltip>
              )}
            </Grid>
          );
        })}
    </Grid>
  );
}

const useHealthScoreConfig = () => {
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteId, setDeleteId] = useState('');
  const [makePrimary, setMakePrimary] = useState();
  const [actionType, setActionType] = useState('');
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const handleEditClick = (id) => {
    navigate(`/kpi-score-configuration/update-health-score/${id}`);
  };

  const { activePackage } = usePackageSelection();

  useEffect(() => {
    const packageId = activePackage && activePackage.packageId;
    if (packageId) {
      dispatch(getHealthInsightsList({ packageId }));
    }
  }, [activePackage?.packageId]);
  const packageId = activePackage && activePackage.packageId;

  const [healthScoreListData, setHealthScoreListData] = useState([]);
  const healthInsights = useSelector((state) => state.healthInsights);
  const { healthInsightsList } = healthInsights;

  const handleCopyClick = (id) => {
    navigate(`/kpi-score-configuration/update-health-score/${id}`, {
      state: {
        isCopyClick: true,
      },
    });
  };

  const handleMakePrimaryClick = async (id) => {
    const data = {
      healthScoreId: id,
      primaryHealthScore: true,
    };
    setOpenDialog(true);
    setMakePrimary(data);
    setActionType('makePrimary');
  };
  const handleMakePrimaryConfirm = useCallback(() => {
    dispatch(resetAccountsList());
    dispatch(updateHealthScore({ data: makePrimary, packageId }));
    setOpenDialog(false);
  }, [makePrimary]);

  const handleDeleteConfirm = useCallback(() => {
    dispatch(deleteHealthScore(deleteId)).then((res) => {
      if (res.error) {
        dispatch(openSnackbar({ open: true, message: res.payload, type: 'error' }));
      }
    });
    setOpenDialog(false);
  }, [deleteId]);
  const handleDelete = async (id) => {
    setOpenDialog(true);
    setDeleteId(id);
    setActionType('delete');
  };

  const handleAddNewScore = () => {
    navigate('/kpi-score-configuration/new-health-score');
  };

  useEffect(() => {
    setHealthScoreListData(healthInsightsList);
  }, [healthInsightsList]);

  const healthScoreList = healthScoreListData
    .filter((item) => item && item.healthScoreLabel)
    .sort((a, b) => a.healthScoreLabel.toLowerCase().localeCompare(b.healthScoreLabel.toLowerCase()));

  const actionCellData = [
    {
      id: 'make_primary',
      name: 'Make Primary',
      disabled: false,
      icon: AutoAwesomeIcon,
      handleFunction: handleMakePrimaryClick,
    },
    {
      id: 'copy',
      name: 'Copy',
      icon: ContentCopyIcon,
      disabled: false,
      handleFunction: handleCopyClick,
    },
    {
      id: 'edit',
      name: 'Edit',
      icon: DriveFileRenameOutlineRoundedIcon,
      disabled: false,
      handleFunction: handleEditClick,
    },
    {
      id: 'delete',
      name: 'Delete',
      icon: DeleteIcon,
      disabled: false,
      handleFunction: handleDelete,
    },
  ];
  const HealthScoreColumns = [
    {
      field: 'healthScoreLabel',
      headerName: 'Label',
      minWidth: 300,
      flex: 1,
      sortable: true,
      renderCell: (params) => <LabelCell mutedText label={params.row.healthScoreLabel} isPrimary={params.row.primaryHealthScore} />,
    },
    {
      field: 'lastModified',
      headerName: 'Last Modified',
      width: 60,
      minWidth: 100,
      flex: 1,
      headerAlign: 'center',
      align: 'center',
      headerClassName: 'center-align--header',
      sortable: true,
      renderCell: (params) => moment(params.value).fromNow(),
    },
    {
      field: 'Actions',
      headerName: 'Actions',
      width: 450,
      align: 'center',
      headerAlign: 'center',
      sortable: false,
      renderCell: (params) => <ActionCell actionCellData={actionCellData} params={params} healthScoreList={healthScoreList} />,
    },
  ];
  const handleClose = () => {
    setOpenDialog(false);
  };

  return {
    handleMakePrimaryClick,
    handleDelete,
    healthScoreList,
    handleAddNewScore,
    handleEditClick,
    HealthScoreColumns,
    openDialog,
    handleMakePrimaryConfirm,
    actionType,
    handleClose,
    handleDeleteConfirm,
    loading: healthInsights.loading,
    data: healthInsightsList,
    error: healthInsights.error,
  };
};

export default useHealthScoreConfig;
