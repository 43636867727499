import {
  Grid, Stack, Typography,
} from '@mui/material';
import { useLocation } from 'react-router-dom';
import useAddEditHeathInsights from './useAddEditHeathInsights';
import RuleForm from '../RuleForm/RuleForm';

function AddEditHealthInsights() {
  const {
    validationSchema,
    handleSubmit,
    isEditPage,
    isAddPage,
    healthScoreDetailsData,
    handleEditSubmit,
    handleRuleChange,
    featureManagerListData,
    initialRuleState,
  } = useAddEditHeathInsights();
  const ruleFormInitialValues = isEditPage
    ? healthScoreDetailsData
    : { healthScoreRules: initialRuleState.healthScoreRules };
  const location = useLocation();
  const isCopyClick = location.state ? location.state.isCopyClick : false;
  let text;
  if (isCopyClick) {
    text = 'Add ';
  } else if (isEditPage) {
    text = 'Edit ';
  } else {
    text = 'Add ';
  }

  let submitHandler;
  if (isCopyClick) {
    submitHandler = handleSubmit;
  } else if (isEditPage) {
    submitHandler = handleEditSubmit;
  } else {
    submitHandler = handleSubmit;
  }
  return (
    <Stack spacing={2} style={{ padding: 24 }}>
      <Grid style={{ backgroundColor: 'white', padding: 15 }}>
        <Typography variant="h4">
          {text}
          KPI Score Configuration
        </Typography>

      </Grid>
      {((isEditPage && healthScoreDetailsData) || isAddPage) && (
        <RuleForm
          initialValues={ruleFormInitialValues}
          validationSchema={validationSchema}
          onSubmit={submitHandler}
          handleRuleChange={handleRuleChange}
          featureManagerListData={featureManagerListData}
          isEditPage={isEditPage}
          initialRuleState={initialRuleState}
          isCopyClick={isCopyClick}
        />
      )}
    </Stack>
  );
}

export default AddEditHealthInsights;
