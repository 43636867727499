import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Stack, useMediaQuery } from '@mui/material';
import AutoAwesomeOutlinedIcon from '@mui/icons-material/AutoAwesomeOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { openSnackbar } from '../../../../features/SnackBar/snackBarSlice';
import { setAccPrimaryHealthScore } from '../../../../features/AccountHealthScore/accountHealthScoreAction';
import { CustomIcon } from '../../../../components/CustomIcon';
import { explainHealthScore } from '../../../../features/HealthScore/HealthScoreAction';
import useToggle from '../../../../hooks/useToggle';
import { CustomCircularProgress } from '../../../../components/CustomCircularProgress';
import { LabelCell } from '../../../../sections/HealthScoreConfig/useHealthScoreConfig';

const useAccountHealthScore = ({
  orgId, packageId, isShare, tenantId, accountOrgId, setPrimaryScoreObj, permissions,
}) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();

  const accountHealthScoreData = useSelector((state) => state.accountHealthScore);
  const [accountHealthScore, setAccountHealthScore] = useState({
    accountsLicense: {},
    error: null,
    loading: true,
  });

  const getSortedHealthScore = (unsortedData) => {
    const rows = unsortedData && unsortedData.length > 0
      ? unsortedData.map((item) => ({
        id: item.healthScoreId,
        healthScoreLabel: item.healthScoreLabel,
        progress: item.healthScore,
        isPrimary: item.isPrimary,
      }))
      : [];
    const sortedRows = rows.slice().sort((a, b) => b.isPrimary - a.isPrimary);
    return sortedRows;
  };

  const setAccountHealthScoreData = () => {
    setAccountHealthScore({
      accountsLicense: getSortedHealthScore(accountHealthScoreData?.accountsLicense),
      loading: accountHealthScoreData?.loading,
      error: accountHealthScoreData?.error,
    });
  };

  const handleClickSettings = () => {
    navigate('/kpi-score-configuration');
  };

  const [open, setOpen] = useToggle();
  const [selectedHealthScore, setSelectedHealthScore] = useState(null);
  const [openCnfDialog, setOpenCnfDialog] = useToggle();
  const [cnfDialogData, setCnfDialogData] = useState({
    id: null, score: null, title: '', message: '',
  });

  const handleClickExplain = (id, score) => {
    setOpen();
    setSelectedHealthScore(score);
    if (id) {
      dispatch(explainHealthScore({
        orgId, packageId, healthScoreId: id, isShare, tenantId, accountOrgId,
      }));
    }
  };

  const handleMakePrimary = () => {
    const { id, score } = cnfDialogData;
    const isReset = !id;

    dispatch(
      setAccPrimaryHealthScore({
        orgId,
        packageId,
        healthScoreId: id,
        healthScore: score,
        isReset,
      }),
    ).then((res) => {
      if (res?.payload?.status === 'SUCCESS') {
        setPrimaryScoreObj(res.payload.data);
        const message = isReset ? 'Primary KPI score set to default' : 'Primary KPI score updated successfully';
        dispatch(openSnackbar({ open: true, message, type: 'success' }));
      } else {
        const errorMessage = 'Failed to update primary KPI score';
        dispatch(openSnackbar({ open: true, message: errorMessage, type: 'error' }));
      }
    });

    setOpenCnfDialog();
  };

  const handleClickMakePrimary = (row) => {
    const { id, progress, healthScoreLabel } = row;
    const message = `Are you sure you want to set ${healthScoreLabel} as primary KPI score for this account?`;
    setCnfDialogData({
      id, score: progress, message, title: 'Update Primary KPI Score',
    });
    setOpenCnfDialog();
  };

  const handleClickReset = () => {
    const message = 'Are you sure you want to reset primary KPI score to default?';
    setCnfDialogData({
      id: null, score: null, message, title: 'Reset Primary KPI Score',
    });
    setOpenCnfDialog();
  };

  const columns = [
    {
      field: 'healthScoreLabel',
      minWidth: useMediaQuery('(max-width:600px)') ? ' ' : 300,
      flex: 1.5,
      renderCell: (params) => (
        <Stack direction="row" gap={2} alignItems="center">
          <Stack
            onClick={() => handleClickExplain(params.row.id, params.row.progress)}
            alignItems="center"
            sx={{ cursor: 'pointer' }}
          >
            <CustomCircularProgress width={35} height={35} value={params.row.progress} />
          </Stack>
          <LabelCell isPrimary={params.row.isPrimary} label={params.value} />
        </Stack>
      ),
    },
    {
      field: 'primary',
      type: 'number',
      minWidth: 120,
      flex: 0.5,
      renderCell: (params) => (
        <Stack direction="row" gap={1} alignItems="center">
          {(permissions.write && !isShare) && (
          <CustomIcon
            tooltip="Make Primary KPI Score for this account"
            Icon={AutoAwesomeOutlinedIcon}
            onClick={() => handleClickMakePrimary(params.row)}
            hidden={params.row.isPrimary}
          />
          )}
          <CustomIcon
            tooltip="Explain KPI Score"
            Icon={InfoOutlinedIcon}
            onClick={() => handleClickExplain(params.row.id, params.row.progress)}
          />
        </Stack>
      ),
    },
  ];

  useEffect(() => {
    setAccountHealthScoreData();
  }, [accountHealthScoreData]);

  return {
    open,
    setOpen,
    openCnfDialog,
    setOpenCnfDialog,
    cnfDialogData,
    selectedHealthScore,
    accountHealthScore,
    columns,
    handleClickSettings,
    handleMakePrimary,
    handleClickReset,
    error: accountHealthScore?.error,
    loading: accountHealthScore?.loading,
  };
};

export default useAccountHealthScore;
